<template>
  <div class="notas d-flex wrap">
    <div class="col-lg-4 col-md-6 col-sm-12 v-10" v-for="(note, index) in notes" :key="index">
      <div class="note">
        <div class="card">
          <div class="card-body">
            <!-- absolute item -->
            <button class="btn close-btn-2" @click="deleteNote(index, note.ID)"><i class="bi bi-x"></i></button>
            <h5 class="card-title font-weight-bold txt-title">{{ note.title }}</h5>
            <h6 class="card-subtitle text-muted">{{ note.author }}</h6>
            <p class="card-text">{{ note.description }}</p>
            <p class="card-text text-muted txt-created">{{ note.created }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notes',
  props: {
    notes: null
  },
  methods: {
    deleteNote(index, id) {
      this.$emit('deleteNoteEmit', index, id);
    }
  }
}
</script>

<style scoped> 
* {
  margin: 0;
  padding: 0;
}
.note {
  position: relative;
}
.card {
  padding-left: 2.1vh;
  padding-right: 2.1vh;
}
.close-btn-2 {
  position: absolute;
  top: 3px;
  right: 2px;
}
.notas {
  width: 100%;
}
.wrap {
  flex-wrap: wrap;
}
.v-10 {
  padding: 1vh;
}
.rounded {
  border-radius: 10px;
}
.indice {
  padding-top: 8px;
  float: left; 
}
.close-btn {
  float: right; 
}
/* Text elements */
.card-title {
  padding: 1vh;
  font-size: 2.8vh;
}
.card-text {
  padding: 0.4vh;
  font-size: 2vh;
}
.card-subtitle {
  padding: 0.4vh;
  font-size: 2.1vh;
}
.txt-title {
  padding-top: 1.5vh;
}
.txt-created {
  padding-bottom: 1.5vh;
}
</style>
