<template>
  <div class="switch">
    <p class="ofline">Ofline</p>
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" id="customSwitch" v-model="selected">
      <label class="custom-control-label" for="customSwitch"></label>
    </div>
    <p class="online">Online</p>
  </div>
</template>

<script>
export default {
  name: 'ModeSwitch',
  data() {
    return {
      selected: false
    }
  },
  methods: {
    emitChange() {
      this.$emit('modeChange', this.selected);
    }
  },
  watch: {
    selected: function() {
      this.emitChange();
    }
  }
}
</script>

<style scoped> 
.switch {
  display: inline;
}
.switch p, div {
  display: inline;
}
.switch .ofline {
  margin-right: 10px;
}
.switch .ofline {
  margin-left: 7px;
}
.ofline, .online {
  font-size: 2vh;
}
/* must be checked */
.custom-switch {
  font-size: 2vh;
}
.custom-control-label {
  font-size: 2vh;
}
</style>
