<template>
  <div class="home container-fluid">
    <div class="switch-section" v-if="loged">
      <ModeSwitch @modeChange="modeChange"/>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 m-10 bg-danger rounded" v-if="errored">
      <p class="card-text text-white font-weight-bold rounded">Error</p>
    </div>
    <div v-if="loading">
      <div class="spinner-grow spinner-grow-sm green" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow spinner-grow-sm green" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow spinner-grow-sm green" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <Notes v-else :notes="notes" @deleteNoteEmit="deleteNote" />
    <div class="botonAgregar float-icon">
       <router-link to="/create">
          <button class="btn btn-success"><i class="bi bi-plus float-icon-icon"></i></button>
       </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Notes from '@/components/Notes.vue'
import ModeSwitch from '@/components/ModeSwitch.vue'

export default {
  name: 'Home',
  components: {
    Notes,
    ModeSwitch
  },
  data(){
    return {
      notes: [
        {
          title: "Don't you have any notes yet?", 
          description: 'Is it a more extensive task? detail everything about it!',
          author: 'Customize the signature of your notes',
          created: 'Keep track of the date you created your notes'
        }
      ],
      loged: false,
      token: null,
      onlineMode: false,
      errored: false,
      loading: true
    }
  },
  methods: {
    // Mode change online or offline
    modeChange(value) {
      this.onlineMode = value;
      if (this.onlineMode) {
        this.notes = []
        this.getOnlineNotes();
      } else {
        // Get local notes
        this.notes = []
        this.getNotes();
      }
    },
    // Local notes functions
    getNotes() {
      setTimeout(() => {
        this.loading = false;
        let storedNotes = localStorage.notes;
        if (storedNotes != undefined && storedNotes != null) {
          if (storedNotes != "[]") {
            this.notes = JSON.parse(storedNotes);
          }
        } 
      }, 1500);
    },
    deleteNote(index) {
      this.notes.splice(index, 1);
      this.saveNotes();
    },
    saveNotes(){
      localStorage.notes = JSON.stringify(this.notes);
    },
    // Online notes functions
    async getOnlineNotes() {
      var datos = new FormData();
      datos.append("token", this.token);
      var url = 'https://cronos8studio.com/smallnotesBack/php/getNotes.php';
      // send data
      try {
        let response = await fetch(url, {
          method: 'POST',
          body: datos
        })
        // response
        if (response) {
            let jsonResponse = await response.json();
            console.log(jsonResponse);

            // get token data
            if (jsonResponse.ok) {
              this.notes = jsonResponse.value;
            } 
        }
      } catch(error) {
        console.log(error);
      }
    },
    // Check token
    async checkToken() {
      var datos = new FormData();
      datos.append("token", this.token);
      var url = 'https://cronos8studio.com/smallnotesBack/php/checkToken.php';
      // send data
      try {
        let response = await fetch(url, {
          method: 'POST',
          body: datos
        })
        // response
        if (response) {
            let jsonResponse = await response.json();
            console.log(jsonResponse);

            // get token data
            if (jsonResponse.ok) {
              this.loged = true;
            } else {
              this.token = null;
              localStorage.removeItem("token");
            }
        }
      } catch(error) {
        console.log(error);
      }
    }
  },
  // Execute when mounted
  mounted() {
    this.$nextTick(function () {
      // Viewport 
      var viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute("content", viewport.content + ", height=" + window.innerHeight);
      // Check Token
      if (localStorage.getItem("token") != undefined) {
        this.token = localStorage.getItem("token");
        this.checkToken();
      }
      // Get local notes
      this.getNotes();
    })
  }
}
</script>

<style scoped>
.green {
  color: #42b883;
}
.spinner-grow {
  margin: 5px;
}
.float-icon {
  position: fixed;
  bottom: 3vh;
  right: 3vh;
}
.float-icon button {
  border-radius: 26px;
}
.float-icon-icon {
  font-size: 1.7rem;
}
</style>
